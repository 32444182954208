import React from "react"
import styled from "styled-components"

const CustomSvg = styled.svg`
  cursor: pointer;
  height: auto;
  margin: 0 auto;
  overflow: visible;
  width: 28px;

  path {
    background: linear-gradient(135deg, #01abd4 0%, #0076ff 100%);
    height: 100%;
    width: 100%;
  }
`

export const Logo = () => (
  <CustomSvg
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Logo"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2838 0.000115753C16.8658 0.00719919 17.3953 0.338681 17.6558 0.859216L28.3354 22.1925C28.6183 22.7576 28.5312 23.4377 28.1151 23.9132L26.9444 22.8889C28.1151 23.9132 28.1144 23.9141 28.1136 23.915L28.112 23.9168L28.1086 23.9207L28.1008 23.9294L28.0815 23.9506C28.067 23.9664 28.0492 23.9855 28.028 24.0075C27.9855 24.0517 27.9293 24.1079 27.8587 24.1744C27.7173 24.3075 27.5182 24.4814 27.2552 24.6818C26.7286 25.083 25.9491 25.5876 24.869 26.0814C22.7036 27.0713 19.3692 28 14.5 28C9.63077 28 6.29642 27.0713 4.13103 26.0814C3.05086 25.5876 2.27137 25.083 1.74476 24.6818C1.48177 24.4814 1.28269 24.3075 1.14134 24.1744C1.07068 24.1079 1.0145 24.0517 0.972011 24.0075C0.950767 23.9855 0.932949 23.9664 0.918459 23.9506L0.899208 23.9294L0.891431 23.9207L0.888001 23.9168L0.8864 23.915C0.885631 23.9141 0.884875 23.9132 2.05555 22.8889L0.884875 23.9132C0.483548 23.4546 0.387035 22.8038 0.637972 22.2484L6.6113 9.02774C6.81618 8.5743 7.22562 8.24622 7.71283 8.14512C8.20003 8.04402 8.7062 8.1821 9.07458 8.5166L10.2411 9.57582L14.8912 0.825572C15.1644 0.311533 15.7017 -0.0069678 16.2838 0.000115753ZM3.96304 22.4451C4.31205 22.6801 4.79277 22.9631 5.42451 23.2519C7.14801 24.0398 10.0359 24.8889 14.5 24.8889C18.9641 24.8889 21.852 24.0398 23.5755 23.2519C24.183 22.9742 24.6509 22.7018 24.9963 22.4723L16.2236 4.94805L12.0498 12.802C11.8245 13.2259 11.4162 13.522 10.9432 13.6044C10.4703 13.6869 9.98585 13.5463 9.63046 13.2236L8.56611 12.2572L3.96304 22.4451Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-13.5001"
        y1="14"
        x2="14.4999"
        y2="42"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01ABD4" />
        <stop offset="1" stopColor="#0076FF" />
      </linearGradient>
    </defs>
  </CustomSvg>
)
